.position-overview .Accordion {
    border-top: none;
}

.position-overview .performance .row {
    margin-bottom: 0;
}

.position-overview .performance .AccordionPanel-content.AccordionPanel-content__open {
    z-index: 2;
    overflow: visible;
}
