.setup-portfolio-form {
    padding-top: var(--padding-base);
    padding-bottom: var(--padding-base);
    border-top: var(--border-base);
}

.setup-portfolio-form > .form-group {
    width: 50%;
    margin: 0 auto var(--margin-base);
}

.setup-portfolio-form > .form-group:last-child {
    margin-bottom: 0;
}

.setup-portfolio-form .form-group .TextInput-group-addon {
    padding: 0;
}

@media (max-width: 576px) {
    .setup-portfolio-form > .form-group {
        width: 100%;
    }
}
