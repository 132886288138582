.single-portfolio-edit h1 {
    margin-bottom: calc(var(--indent-base) * 2);
}

.single-portfolio-edit h1 + button {
    margin-top: -55px;
}

@media (min-width: 541px) {
    .single-portfolio-edit .table tr td {
        height: 65px;
        padding-top: 0;
        padding-bottom: 0;
    }
}

.single-portfolio-edit .table tr th.column-fixed {
    text-align: center;
}

.single-portfolio-edit .table tr td.cell-fixed {
    background-color: transparent;
}

.single-portfolio-edit__buttons {
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
    margin-top: calc(var(--indent-base) * 2);
}

.single-portfolio-edit__buttons .button + .button {
    margin-left: var(--margin-base);
}

.single-portfolio-edit__header {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: column;
    width: 100%;
}

.single-portfolio-edit__header h3 {
    margin: 0;
}

.single-portfolio-edit .Accordion {
    width: auto;
    margin: 0 calc(var(--indent-base) * -2);
}

.single-portfolio-edit .AccordionPanel-content-box {
    position: relative;
    bottom: -1px;
    padding-bottom: 0 !important;
}

.single-portfolio-edit .table-wrapper {
    margin-bottom: 0;
    padding: 0 var(--padding-base);
}

.single-portfolio-edit .table-wrapper tr td.with-input {
    padding: 12px var(--margin-base) 12px var(--margin-base);
}

.single-portfolio-edit .table-wrapper tr td.with-link {
    padding: 25px 0;
}

.single-portfolio-edit .table-row:last-child .table-cell {
    border: 0;
}

.single-portfolio-edit .table-cell-header {
    padding-top: 0;
}

.single-portfolio-edit .form-group {
    margin-bottom: 0;
}

@media only screen and (min-width: 576px) {
    .single-portfolio-edit__header {
        align-items: center;
        flex-direction: initial;
    }
}
