.review-page-overview {
    padding: var(--padding-base);
}

.review-page-overview > .row {
    width: calc(100% + 2 * var(--grid-column-padding));
}

.review-page-overview .overview-item {
    height: auto;
    width: 100%;
    display: block;
}

.review-page-overview .overview-item__value_small {
    line-height: 18px;
}
