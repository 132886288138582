.news {
    --news-padding: 10px;
    margin-bottom: 10px;
}

.news .Pagination {
    width: calc(100% + 60px);
    margin: 17px calc(0 - var(--padding-base)) 0 calc(0 - var(--padding-base));
    border-top: var(--border-base);
    padding-top: var(--padding-base);
}

.news .load-more {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: var(--padding-base);
}

.news .load-more--btn:hover,
.news .load-more--btn:focus,
.news .load-more--btn:active {
    background-color: transparent;
}
