.knowledge-and-experience-results .stepper-content {
    padding-bottom: var(--padding-base);
}

.knowledge-and-experience-results .Paragraph_primary {
    font-size: 1.8rem;
    margin-bottom: 0;
}

.knowledge-and-experience-results .Info-box {
    margin-top: var(--margin-base);
}

.knowledge-and-experience-results .negative-results {
    border-top: var(--border-base);
    padding-top: var(--padding-base);
    margin-top: var(--padding-base);
}

.knowledge-and-experience-results .negative-results.without-border {
    border-top: none;
    padding-top: 0;
}

.knowledge-and-experience-results .instrument-block {
    padding-top: var(--padding-base);
}

.knowledge-and-experience-results .instrument-block .Title {
    --title-4-margin-bottom: 5px;
    font-weight: 700;
}

.knowledge-and-experience-results .instrument-block .Paragraph_secondary {
    margin-bottom: 0;
}

.knowledge-and-experience-results .positive i {
    font-size: 32px !important;
}

.knowledge-and-experience-results .negative,
.knowledge-and-experience-results .positive {
    display: flex;
    justify-content: left;
    align-items: center;
}

.knowledge-and-experience-results .negative .icon,
.knowledge-and-experience-results .positive .icon {
    margin-right: var(--margin-base);
}

@media (max-width: 576px) {
    .knowledge-and-experience-results .negative,
    .knowledge-and-experience-results .positive {
        align-items: flex-start;
    }

    .knowledge-and-experience-results .negative .icon,
    .knowledge-and-experience-results .positive .icon {
        margin-top: 5px;
    }
}

.knowledge-and-experience-results .positive .icon {
    color: var(--positive-default);
}

.knowledge-and-experience-results .negative-icon {
    position: relative;
    width: 32px;
    height: 32px;
    background-color: var(--negative-default);
    border-radius: 30px;
}

.knowledge-and-experience-results .negative-icon:hover {
    opacity: 1;
}

.knowledge-and-experience-results .negative-icon:before,
.knowledge-and-experience-results .negative-icon:after {
    position: absolute;
    left: 14px;
    top: 7px;
    height: 19px;
    width: 4px;
    content: ' ';
    border-radius: 2px;
    background-color: var(--white);
}

.knowledge-and-experience-results .negative-icon:before {
    transform: rotate(45deg);
}

.knowledge-and-experience-results .negative-icon:after {
    transform: rotate(-45deg);
}
