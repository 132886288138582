.stepper-content-review {
    margin: var(--padding-base) auto 0;
    max-width: 880px;
    width: 100%;
}

.risk-profile-review .header-with-description {
    border-bottom: var(--border-base);
    margin-bottom: var(--margin-base);
}

.risk-profile-review .header-with-description  h2.Title {
    border-bottom: none;
    margin-bottom: var(--indent-base);
    padding-bottom: 0;
}

.risk-profile-review .header-with-description   .Paragraph {
    padding-bottom: calc(var(--indent-base) / 3);
}
