.new-message,
.reply-message {
    margin-bottom: 10px;
}

.new-message .content-box:first-child,
.reply-message .content-box:first-child {
    border-bottom: 1px solid var(--border-color);
}

.new-message .buttons,
.reply-message .buttons {
    margin: 0 calc(var(--padding-base) * -1) !important;
    padding: var(--padding-base) var(--indent-base) 0;
    border-top: 1px solid var(--border-color);
}

.new-message hr,
.reply-message hr {
    height: 1px;
    margin: var(--padding-base) calc(var(--padding-base) * -1);
    border: none;
    color: var(--secondary-lighter);
    background-color: var(--secondary-lighter);
}

.message-content .Paragraph_primary {
    overflow-y: auto;
    padding: var(--padding-base) 0;
    margin-bottom: var(--padding-base);
}

.delete-message-modal .button-primary {
    padding-left: 17px;
}

.Tags .tags-list ul {
    margin-bottom: 0;
}

.messages .messages-list .widget__empty {
    color: var(--secondary-default);
    margin-top: var(--padding-base);
    text-align: center;
}

.messages .messages-list .widget__button {
    text-align: center;
}
