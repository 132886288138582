.text-right {
    text-align: right !important;
}

.text-left {
    text-align: left !important;
}

@media (min-width: 576px) {
    .text-sm-right {
        text-align: right !important;
    }
}

dd {
    margin-bottom: 0;
    margin-left: 0;
}

.with-mb {
    margin-bottom: var(--padding-base);
}

.with-mt {
    margin-top: var(--padding-base);
}

#root {
    --content-box-mobile-margin: 0 calc(var(--indent-base) * -1);
    --content-box-mobile-padding: var(--margin-base);

    display: flex;
    flex-direction: column;
    background: var(--background-base);
}

.Loader-wrapper {
    padding-top: calc(var(--padding-base) * 2.333);
    padding-bottom: calc(var(--margin-base) * 2.5);
}

.button .Loader-wrapper {
    margin: 0 auto;
    padding: 0;
}

#root .buttons-block .button {
    float: right;
    margin-left: var(--margin-base);
    margin-top: 28px;
}

#root .button-group:not(.button-group--horiz) button + button {
    margin-left: var(--margin-base);
}

#root .Title a {
    color: inherit;
}

#root .Title .Icon {
    display: inline-block;
    vertical-align: middle;
    margin-left: 7px;
    font-size: 12px;
    line-height: inherit;
}

main.container {
    --container-padding-horizontal: 15px;
}

@media (min-width: 768px) {
    main.container {
        --container-padding-horizontal: 40px;
    }
}

@media (max-width: 767px) {
    .Content-box {
        margin: var(--content-box-mobile-margin);
        padding: var(--content-box-mobile-padding);
        border-radius: 0;
    }

    html[data-theme='light'] .Content-box:not(.PortfoliosCard),
    html[data-theme='dark'] .Content-box:not(.PortfoliosCard) {
        --content-box-shadow: none;
    }

    .Content-box > .row {
        padding-bottom: var(--indent-base);
    }
}

.Title.underlined {
    padding-bottom: var(--padding-base);
    margin-bottom: 0;
    margin-left: calc(var(--padding-base) * -1);
    margin-right: calc(var(--padding-base) * -1);
    padding-left: var(--padding-base);
    padding-right: var(--padding-base);
    border-bottom: var(--border-base);
}

.Title.underlined.with-mb {
    margin-bottom: var(--padding-base);
}

.table-without-last-row-padding .Table:not(.hasPagination) tbody tr:last-child td {
    padding-bottom: 0;
}

.table-without-head-row-padding .Table thead tr th {
    padding-top: 0;
}

.mt-5 {
    margin-top: 5px;
}
