.infobox-with_button {
    align-items: center;
}

.infobox-with_button > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

@media (max-width: 767px) {
    .infobox-with_button {
        align-items: flex-start;
    }

    .infobox-with_button > div {
        flex-wrap: wrap;
    }

    .infobox-with_button .infobox__right {
        margin-top: calc(var(--margin-base) / 2);
    }
}
