.performance .AccordionPanel-content.AccordionPanel-content__open,
.analysis .AccordionPanel-content.AccordionPanel-content__open {
    z-index: 2;
    overflow: visible;
}

.analysis .column-center {
    grid-column: col 4 / span 6;
}

@media (max-width: 992px) {
    .analysis .column-center {
        grid-column: span 12;
    }

    .analysis .column-center .Highcharts-projection-chart {
        margin-left: calc(var(--padding-base) * -1);
        margin-right: calc(var(--margin-base) * -1 / 2);
        width: calc(100% + var(--padding-base) * 1.5);
    }
}

.analysis .AccordionPanel-content-box {
    padding-bottom: var(--indent-base);
}

.analysis .Tabs-content .row {
    --row-margin-bottom: 0;
}

.analysis .Tabs-content .LineChart {
    padding-top: 5px;
    margin-bottom: -25px;
}

.analysis .Tabs-content .Risk-return-container {
    margin-top: -9px;
    margin-bottom: 0;
}

.analysis .Tabs-content .Risk-return-container .Risk-return-legend {
    line-height: 10px;
}

.analysis .Tabs-content .Info-box {
    margin: var(--indent-base) auto 0;
}

.analysis .Risk-return-container {
    margin: 0 auto var(--indent-base);
    width: fit-content;
}

.analysis .highcharts-container .highcharts-background {
    width: 0;
}

.analysis .Loader-wrapper {
    margin: 0 auto !important;
    padding-bottom: var(--indent-base);
}

.analysis .highcharts-tooltip > span {
    padding: 7px !important;
    outline: 7px solid var(--tooltip-bg);
    background: var(--tooltip-bg);
    white-space: nowrap !important;
}

.analysis .highcharts-tooltip > span .value {
    margin-left: 10px;
}

@media (max-width: 767px) {
    .analysis .Tabs-bar__extra > div {
        margin: 0;
        width: 100%;
    }
}

.analysis .Tabs-bar .extra-select {
    margin-left: auto;
}

.analysis .Tabs-bar .selects__more {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.analysis .Tabs-bar .selects__more > div {
    width: 50%;
}

.analysis .Tabs-bar .selects__more > div:first-child {
    margin-right: var(--margin-base);
}

.analysis .Tabs-bar .selects__more .form-group {
    max-width: 100%;
}

.analysis .Tabs-bar .selects__more .select {
    max-width: 100%;
}

@media (max-width: 990px) {
    .analysis .Tabs-bar .Tabs-bar__extra {
        width: calc(50% - var(--margin-base));
    }

    .analysis .Tabs-bar .selects__more .select {
        width: 100% !important;
    }
}
