.funding-information .funding-information-content {
    margin: 0 auto;
    max-width: 698px;
    width: 100%;
}

.funding-information .row {
    margin-bottom: 0;
}

.funding-information .funding-information-text {
    border-bottom: var(--border-base);
    padding-bottom: calc(var(--padding-base) - 10px);
}

.funding-information .group-title {
    margin-top: calc(var(--margin-base) / 2);
}

.funding-information .group-title .Title {
    margin-bottom: calc(var(--margin-base) / 2);
    font-weight: bold;
}

.funding-information .Info-box {
    margin-top: var(--padding-base);
}

.funding-information .bank-info > div:first-child {
    margin-bottom: var(--margin-base);
}

.funding-information .bank-info .Title {
    font-size: 1.4rem;
}

.funding-information .bank-info-description {
    color: var(--primary-default);
    font-size: 1.4rem;
    line-height: 1.57142857;
    margin-bottom: 0;
}

.funding-information .buttons-block-row .buttons-block__col:last-child .button:nth-child(2) {
    margin-top: var(--buttons-block-row__button-margin);
}

.funding-information .funding-information-header {
    display: flex;
    justify-content: space-between;
    border-bottom: var(--border-base);
    padding: 0 var(--padding-base);
    padding-bottom: var(--indent-base);
    margin: 0 calc(var(--padding-base) * -1);
}

.funding-information .buttons-block-row {
    border-top: var(--border-base);
    background-color: var(--background-content);
    padding: var(--padding-base);
    margin: 0 calc(var(--padding-base) * -1);
    margin-top: var(--padding-base);
}

.funding-information .funding-information-details {
    margin-top: var(--margin-base);
}
