.cell-name-input {
    display: inline-block;
    white-space: nowrap;
    padding-right: 10px;
}

.row-level-1 .cell-name-input {
    width: 100%;
}

.cell-name-input .form-group {
    margin-top: calc(var(--margin-base) / 2);
}

.cell-name-input b {
    float: right;
}

tfoot .cell-name-input {
    width: 100%;
}
