.questionnaire-question:not(:first-child) {
    margin-top: var(--margin-base);
}

.questionnaire-question:not(:last-child) {
    padding-bottom: var(--margin-base);
    border-bottom: var(--border-base);
}

.questionnaire-question .Form-control-label {
    --label-fz: 18px;
    --label-lh: 28px;
}

.questionnaire-question .radio-wrapper {
    --radio-fz: 16px;
    --radio-lh: 26px;
    --radio-label-lh: 26px;
    --radio-group-wrapper-mt: calc(var(--margin-base) / 2);
    display: flex;
    align-items: flex-start;
}

.questionnaire-question .radio-wrapper .radio {
    top: calc((var(--radio-lh) - var(--radio-size)) / 2);
}

.questionnaire-question .checkbox-wrapper {
    --checkbox-fz: 16px;
    --checkbox-lh: 26px;
}
