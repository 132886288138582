.optimize-page .stepper-content {
    padding-bottom: var(--padding-base);
}

.optimize-page .Info-box {
    margin-bottom: calc(var(--indent-base) * 2);
}

.optimize-page .form-group {
    margin-bottom: var(--margin-base);
    width: 50%;
}

.optimize-page .group-title {
    margin-top: var(--margin-base);
    margin-bottom: var(--margin-base);
}

.optimize-page .group-title .Title {
    margin-bottom: calc(var(--margin-base) / 2);
}

.optimize-page .select-goal {
    margin-bottom: 30px;
}

.optimize-page .goal-details {
    padding-top: 10px;
    border-top: var(--border-base);
}

.optimize-page .our-suggestion {
    padding-top: 10px;
    border-top: var(--border-base);
    border-bottom: var(--border-base);
}

.optimize-page .suggestion-text {
    margin-bottom: 20px;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    flex-direction: column;
}

.optimize-page .suggestion-title {
    font-size: 1.6rem;
    line-height: 1.1875;
    font-weight: bold;
    font-family: Roboto, Helvetica, Arial, sans-serif;
}

.optimize-page .suggestion-currency {
    font-size: 1.6rem;
    margin-left: 5px;
    margin-top: 10px;
}

.optimize-page .suggestion-value {
    display: flex;
    align-items: center;
    font-size: 3.2rem;
    line-height: 1.125;
}

.optimize-page .suggestion {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    align-items: center;
}

.optimize-page .suggestion-right {
    width: 50%;
}

.optimize-page .chart {
    margin-top: 45px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
}

.optimize-page .chart .highcharts-tooltip>span {
    padding: 7px !important;
    outline: 7px solid var(--white);
    background: var(--white);
    white-space: nowrap !important;
}

.optimize-page .chart .highcharts-tooltip>span .tooltip-row:first-child {
    font-weight: bolder;
}

.optimize-page .chart .highcharts-tooltip>span .value {
    display: block;
    font-weight: normal;
    margin-top: 5px;
}

.optimize-page .chart .ProjectionChart-container .highcharts-series-group .highcharts-color-4 {
    stroke: var(--background-content) !important;
    stroke-width: 2px !important;
}

.optimize-page .chart .Loader-wrapper {
    height: auto;
}

.optimize-page .positive i {
    color: var(--positive-default);
    font-size: 32px !important;
}

.optimize-page .negative,
.optimize-page .positive {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.optimize-page .negative p,
.optimize-page .positive p {
    margin-bottom: 0;
}

.optimize-page .negative .Title,
.optimize-page .positive .Title {
    margin-bottom: calc(var(--margin-base) / 2);
}

.optimize-page .icon {
    margin-right: 15px;
}

.optimize-page .negative-icon {
    position: relative;
    width: 32px;
    height: 32px;
    background-color: var(--negative-default);
    border-radius: 30px;
}

.optimize-page .negative-icon:hover {
    opacity: 1;
}

.optimize-page .negative-icon:before,
.optimize-page .negative-icon:after {
    position: absolute;
    left: 14px;
    top: 7px;
    height: 19px;
    width: 4px;
    content: ' ';
    border-radius: 2px;
    background-color: var(--white);
}

.optimize-page .negative-icon:before {
    transform: rotate(45deg);
}

.optimize-page .negative-icon:after {
    transform: rotate(-45deg);
}

.optimize-page .positive-icon {
    position: relative;
    width: 32px;
    height: 32px;
    background-color: var(--accent-default);
    border-radius: 30px;
}

.optimize-page .positive-icon:after {
    top: 5px;
    left: 12px;
    position: absolute;
    display: inline-block;
    transform: rotate(45deg);
    height: 18px;
    width: 9px;
    border-bottom: 4px solid var(--white);
    border-right: 4px solid var(--white);
    margin-bottom: 10px;
    content: ' ';
}

.optimize-page .ProjectionChart-container .projection-legend {
    margin-top: var(--margin-base);
}

.optimize-page .isOptimize {
    color: var(--accent-default);
}

.optimize-page .isOptimize .suggestion-value span:first-child {
    position: relative;
}

.optimize-page .isOptimize .suggestion-value span:first-child:after {
    position: absolute;
    top: 45%;
    left: 0;
    height: 6px;
    width: 100%;
    box-sizing: border-box;
    border: 2px solid var(--white);
    content: '';
    background-color: var(--accent-default);
}

.optimize-page .optimized {
    display: flex;
    align-items: center;
    margin-left: 5px;
}

.optimize-page .arrow {
    position: relative;
    height: 2px;
    width: 20px;
    margin: 0 auto;
    background: var(--accent-default);
    cursor: pointer;
    margin-right: 5px;
}

.optimize-page .arrow:before,
.optimize-page .arrow:after {
    position: absolute;
    width: 13px;
    height: 2px;
    background: var(--accent-default);
    content: '';
}

.optimize-page .arrow:before {
    right: -3px;
    bottom: -4px;
    transform: rotate(-45deg);
}

.optimize-page .arrow:after {
    right: -3px;
    top: -4px;
    transform: rotate(45deg);
}

@media (max-width: 576px) {
    .optimize-page .suggestion {
        flex-direction: column;
    }

    .optimize-page .suggestion-left,
    .optimize-page .suggestion-right {
        width: 100%;
    }

    .optimize-page .suggestion-value {
        flex-wrap: wrap;
    }

    .optimize-page .negative,
    .optimize-page .positive {
        align-items: flex-start;
    }

    .optimize-page .negative .icon,
    .optimize-page .positive .icon {
        margin-top: 5px;
    }
}
