.client__navigation {
    margin-top: var(--margin-base);
    position: sticky;
    top: 0;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 100vh;
    padding: 2px;
}

.client__navigation .button-link {
    --btn-link-lh: 30px;
    display: block;
    line-height: var(--btn-link-lh);
    height: auto;
    min-width: 100%;
    max-width: 100%;
    text-align: left;
    white-space: normal;
    margin-bottom: calc(var(--margin-base) / 2);
}

.client__navigation .button-link.active {
    font-weight: bold;
}

.client__navigation .button-link.sub-label {
    --btn-fz: 14px;
    --btn-link-lh: 20px;
    margin-left: calc(var(--margin-base) / 2);
    min-width: calc(100% - var(--margin-base));
}

.client__navigation .sub-block {
    display: none;
}

.client__navigation .sub-block.sub-block__opened {
    display: block;
}
