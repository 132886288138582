.PageHeader {
    --PageHeader-border-color: var(--border-color);
    --PageHeader-padding-base: var(--padding-base);
    --PageHeader__paragraph-margin: 5px;

    display: flex;
    border-bottom: 1px solid var(--PageHeader-border-color);
    margin: 0 calc(var(--PageHeader-padding-base) * -1);
    padding: 0 var(--PageHeader-padding-base) var(--PageHeader-padding-base);
}

.PageHeader + .Accordion,
.PageHeader + .AccordionWrapper > .Accordion {
    border-top: none;
}

.PageHeader > div {
    width: 100%;
    padding: 0;
}

.PageHeader .Title {
    margin-bottom: 0;
}

.PageHeader p {
    margin-top: var(--PageHeader__paragraph-margin);
    margin-bottom: 0;
}

@media (max-width: 576px) {
    .PageHeader {
        flex-direction: column;
    }
}
