.instrument-documents-table tbody .table-cell_with-button-icon button .table-cell_icon {
    padding-top: 0;
}

.instrument-documents-table .Icon-save {
    font-size: 18px;
    color: var(--secondary-default);
}

.documents .Info-box {
    margin-top: var(--margin-base);
}
