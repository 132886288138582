.completion .stepper-content {
    padding-bottom: calc(var(--padding-base) / 2);
}

.completion .completion-content {
    margin: var(--padding-base) auto 0;
    max-width: 698px;
    width: 100%;
}

.completion .row {
    margin-bottom: 0;
}

.completion .completion-text {
    border-bottom: var(--border-base);
    padding-bottom: calc(var(--padding-base) - 10px);
}

.completion .group-title {
    margin-top: calc(var(--margin-base) / 2);
}

.completion .group-title .Title {
    margin-bottom: calc(var(--margin-base) / 2);
}

.completion .Info-box {
    margin-top: var(--padding-base);
}

.completion .bank-info > div:first-child {
    margin-bottom: var(--margin-base);
}

.completion .bank-info .Title {
    font-size: 1.4rem;
}

.completion .bank-info-description {
    color: var(--primary-default);
    font-size: 1.4rem;
    line-height: 1.57142857;
    margin-bottom: 0;
}

.completion .documents-info {
    margin-top: calc(var(--margin-base) / 2);
}

.completion .documents-title {
    border-top: var(--border-base);
    padding-top: calc(var(--padding-base) - 10px);
}

.completion .documents-button-group button {
    display: block;
    margin: var(--margin-base) 0;
}

@media screen and (max-width: 576px) {
    .completion .buttons-block-row .buttons-block__col:last-child .button:nth-child(2) {
        margin-top: var(--buttons-block-row__button-margin);
    }

    .completion .documents-button-group button {
        width: 100%;
    }
}
