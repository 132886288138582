.Footer.Footer_mini {
    --language-switcher-min-width: 150px;
    margin-top: auto !important;
}

.Footer .Footer__bottom > span {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}

[dir=rtl] .Footer .Footer__bottom > span {
    text-align: right;
}

.Footer .Footer__bottom .LanguageSwitcher {
    margin-right: var(--padding-base);
    min-width: var(--language-switcher-min-width);
    flex-grow: 1;
}

[dir=rtl] .Footer .Footer__bottom .LanguageSwitcher {
    margin-left: var(--padding-base);
    margin-right: 0;
}

.Footer .Footer__bottom .LanguageSwitcher + span {
    max-width: calc(100% - var(--language-switcher-min-width) - var(--padding-base));
}

@media (max-width: 767px) {
    .Footer .Footer__bottom .LanguageSwitcher span {
        text-align: left !important;
    }

    .Footer .Footer__bottom .LanguageSwitcher {
        min-width: 200px;
        margin-right: 0;
        margin-top: 10px;
        width: 100%;
    }

    .Footer .Footer__bottom .LanguageSwitcher + span {
        max-width: 100%;
    }

    .Footer .Footer__bottom > span {
        display: flex !important;
        flex-direction: column-reverse;
    }

    .Footer_mini .Footer__bottom {
        display: flex;
        align-items: center;
        padding: 30px 15px;
        flex-direction: column-reverse;
    }

    .Footer_mini .Footer__bottom a {
        margin-left: 0;
        margin-bottom: 15px;
    }

    .Footer_mini .Footer__bottom span {
        display: block;
        text-align: center;
    }
}
