/* stylelint-disable */
.Auth {
    background-color: transparent;
}

.Auth .PageVariant .Content-box {
    padding: 0!important;
}

@media screen and (max-width: 767px) {
    .Auth {
        padding-right: 0;
        padding-left: 0;
        min-height: calc(100vh + 5px);
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .Auth {
        min-height: calc(100vh + 5px);
    }

    .Auth .detail_content {
        min-height: calc(100vh - 105px);
        display: flex;
        flex-flow: column;
        justify-content: center;

    }
}

.Auth .detail_content {
    max-width: 354px;
    margin: 0 auto;
    padding-top: 8rem;
    background: transparent;
}

.Auth .detail_content .content-pane-wrapper {
    background-color: var(--background-content);
    border-radius: 5px;
    box-shadow: var(--content-box-shadow);
    padding: var(--padding-base);
}

.Auth .detail_content .content-pane .buttons.button-group {
    text-align: center;
}

.Auth .detail_content .content-pane .buttons.button-group .btn {
    float: none !important;
}

.Auth button,
.Auth .detail_content .content-pane .buttons.button-group button {
    width: 100%;
}

.Auth .detail_content .content-pane .buttons.button-group .button-primary {
    margin-bottom: var(--margin-base);
}

.Auth .application-title {
    margin-bottom: var(--padding-base);
    text-align: center;
}
