.goal-form .form-group {
    margin-bottom: var(--margin-base);
    width: 50%;
}

.goal-form .group-title {
    margin-top: var(--margin-base);
    margin-bottom: var(--margin-base);
}

.goal-form .group-title .Title {
    margin-bottom: calc(var(--margin-base) / 2);
}

.goal-form .select-goal {
    margin-bottom: var(--padding-base);
}

.goal-form .goal-details {
    padding-top: 10px;
    border-top: var(--border-base);
}

.goal-form .investment-details {
    padding-top: calc(var(--margin-base) / 2);
    border-top: var(--border-base);
    border-bottom: var(--border-base);
}

.goal-form .investment-details > .form-group {
    width: 100%;
}

.goal-form .result {
    margin-top: calc(var(--margin-base) / 2);
}

.goal-form .target-value {
    margin-bottom: var(--margin-base);
}

.goal-form .target-value .form-group {
    margin-bottom: 0;
}

.goal-form .target-value .form-group .TextInput-group-addon {
    padding: 0;
}

.goal-form .target-value > .form-group {
    width: 100%;
}

.goal-form .recurring-payment > .form-group {
    width: 100%;
}

.goal-form .chart {
    margin-top: calc(var(--margin-base) * 2.5);
    margin-bottom: calc(var(--margin-base) / 2);
    display: flex;
    justify-content: center;
}

.goal-form .chart .highcharts-tooltip>span {
    padding: 7px !important;
    white-space: nowrap !important;
}

.goal-form .chart .highcharts-tooltip>span .tooltip-row:first-child {
    font-weight: bolder;
}

.goal-form .chart .highcharts-tooltip>span .value {
    display: block;
    font-weight: normal;
    margin-top: calc(var(--margin-base) / 4);
}

.goal-form .chart .ProjectionChart-container .highcharts-series-group .highcharts-color-4 {
    stroke: var(--background-content) !important;
    stroke-width: 2px !important;
}

.goal-form .chart .Loader-wrapper {
    height: auto;
}

.goal-form .positive i {
    color: var(--positive-default);
    font-size: 32px !important;
}

.goal-form .negative,
.goal-form .positive {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.goal-form .negative p,
.goal-form .positive p {
    margin-bottom: 0;
}

.goal-form .negative .Title,
.goal-form .positive .Title {
    margin-bottom: calc(var(--margin-base) / 2);
}

.goal-form .icon {
    margin-right: calc(var(--padding-base) / 2);
}

.goal-form .negative-icon {
    position: relative;
    width: 32px;
    height: 32px;
    background-color: var(--negative-default);
    border-radius: 30px;
}

.goal-form .negative-icon:hover {
    opacity: 1;
}

.goal-form .negative-icon:before,
.goal-form .negative-icon:after {
    position: absolute;
    left: 14px;
    top: 7px;
    height: 19px;
    width: 4px;
    content: ' ';
    border-radius: 2px;
    background-color: var(--white);
}

.goal-form .negative-icon:before {
    transform: rotate(45deg);
}

.goal-form .negative-icon:after {
    transform: rotate(-45deg);
}

.goal-form .positive-icon {
    position: relative;
    width: 32px;
    height: 32px;
    background-color: var(--accent-default);
    border-radius: 30px;
}

.goal-form .positive-icon:after {
    top: 5px;
    left: 12px;
    position: absolute;
    display: inline-block;
    transform: rotate(45deg);
    height: 18px;
    width: 9px;
    border-bottom: 4px solid var(--white);
    border-right: 4px solid var(--white);
    margin-bottom: 10px;
    content: ' ';
}

.goal-form .ProjectionChart-container .projection-legend {
    margin-top: var(--margin-base);
}

@media (max-width: 768px) {
    .goal-form .ProjectionChart-container .projection-legend {
        text-align: center;
    }

    .goal-form .ProjectionChart-container .projection-legend-item:not(:last-child) {
        margin-bottom: calc(var(--margin-base) - 5px);
    }
}

@media (max-width: 576px) {
    .goal-form .form-group {
        width: 100%;
    }

    .goal-form .negative,
    .goal-form .positive {
        align-items: flex-start;
    }

    .goal-form .negative .icon,
    .goal-form .positive .icon {
        margin-top: calc(var(--margin-base) / 4);
    }
}
