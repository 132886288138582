.goal-review .stepper-content .Accordion .AccordionPanel:last-child {
    border-bottom: none;
}

.goal-review .stepper-content .Accordion .AccordionPanel .overview-panel {
    margin: 0 0 calc(var(--padding-base) * -1) 0;
    display: block;
}

.goal-review .stepper-content .Accordion .overview-panel .row {
    --row-margin-bottom: 0;
    width: 100%;
}

.goal-review .stepper-content .Accordion .overview-panel .overview-item {
    height: auto;
    margin-bottom: var(--padding-base);
}

@media (max-width: 576px) {
    .goal-review .buttons-block-row .buttons-block__col:last-child .button:not(:first-child) {
        margin-left: 0;
        margin-top: var(--buttons-block-row__button-margin);
    }
}

.overview-item-center-md .overview-item:last-child {
    margin-top: calc(var(--margin-base) * -1);
}

.on-boarding .top-info-box {
    margin-bottom: var(--padding-base);
}

.goal-review .risk-bandwidt-disclaimer .Info-box {
    margin: var(--padding-base) var(--padding-base) 0 var(--padding-base);
}

.on-boarding .goal-reached .Info-box {
    border-color: var(--infobox-border-color-theme);
}

.on-boarding .investment-performance .AccordionPanel-content.AccordionPanel-content__open {
    z-index: 2;
    overflow: visible;
}
