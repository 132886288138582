.add-position__content {
    padding: var(--on-boarding-box-padding);
}

.add-position__content .table-wrapper {
    border-top: var(--border-base);
}

.add-position__content .select__multi {
    min-width: 140px;
}

.on-boarding_add-position .Info-box {
    margin: 0 var(--padding-base) var(--padding-base) var(--padding-base);
}

.validation-error {
    margin-bottom: var(--padding-base);
}

@media (max-width: 576px) {
    .add-position__content .row > div:not(:first-child) {
        margin-top: var(--margin-base);
    }
}
