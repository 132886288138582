.product-offer-selection {
    --radio-big-size: 24px;
    --radio-label-font: 16px;
    --radio-inner-size-big: 12px;
}

.product-offer-selection .radio-wrapper {
    display: flex;
    justify-content: normal;
    align-items: center;
    width: 100%;
    margin-right: 0;
    margin-bottom: var(--margin-base);
}

.product-offer-selection .radio-wrapper .radio-inner {
    width: var(--radio-big-size);
    height: var(--radio-big-size);
}

.product-offer-selection .radio-wrapper .radio-inner:after {
    width: var(--radio-inner-size-big);
    height: var(--radio-inner-size-big);
    background-size: var(--radio-inner-size-big);
    transition: 0s;
    transform: translate(-50%, -50%);
}

.product-offer-selection .radio-wrapper .radio + span {
    width: 100%;
}

.product-offer-selection .product-offer-content {
    border-top: var(--border-base);
    margin-top: var(--margin-base);
    padding-top: var(--margin-base);
}

.product-offer-selection .image-with-placeholder {
    max-height: 115px;
}
