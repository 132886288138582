.review-radio-group {
    --radio-big-size: 24px;
    --radio-label-font: 16px;
    --radio-inner-size: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.review-radio-group .radio-wrapper {
    width: 24px;
    opacity: 1;
    text-align: center;
    margin: 0;
}

.review-radio-group .radio-wrapper:not(:last-child) {
    margin-right: 20px;
}

.review-radio-group .radio-wrapper:hover .radio,
.review-radio-group .radio-wrapper:hover .radio-inner {
    cursor: default !important;
    background-color: transparent;
}

.review-radio-group .radio-wrapper input {
    cursor: default !important;
}

.review-radio-group .radio-wrapper .radio-inner {
    width: var(--radio-big-size);
    height: var(--radio-big-size);
    cursor: default !important;
    border-color: var(--primary-default) !important;
}

.review-radio-group .radio-wrapper .radio-inner:after {
    width: var(--radio-inner-size);
    height: var(--radio-inner-size);
    background-size: var(--radio-inner-size);
    transition: 0s;
    transform: translate(-50%, -50%);
}

.review-radio-group .radio-wrapper .radio + span {
    display: none;
}
