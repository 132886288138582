.review-portfolios .review-portfolios-table .Icon-warning svg {
    fill: var(--negative-default);
}

.review-portfolios .review-portfolios-table .Table tbody .table-cell_icon {
    color: var(--primary-default);
}

.review-portfolios .header-with-description {
    border-bottom: var(--border-base);
    margin-top: var(--margin-base);
    border-top: var(--border-base);
    padding-top: var(--margin-base);
}

.review-portfolios .header-with-description  h2.Title {
    padding-top: calc(var(--margin-base) / 2);
    border-bottom: none;
    padding-bottom: var(--indent-base);
}

.review-portfolios .header-with-description  .Paragraph {
    padding-bottom: calc(var(--indent-base) / 3);
}
