.PortfoliosList {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--padding-base);
    margin: var(--margin-base) 0;
}

@media screen and (max-width: 992px) {
    .PortfoliosList {
        grid-template-columns: 1fr;
    }
}
