@media (max-width: 767px) {
    .projection-tooltip .tooltip-row {
        display: flex;
        flex-direction: column;
    }

    .projection-tooltip div.tooltip-row > .value {
        margin-left: 0;
    }
}
