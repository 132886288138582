.table-cell_action-with-icon-text {
    display: inline-flex;
    align-items: center;
}

.table-cell_action-with-icon-text .table-cell {
    margin-left: 5px;
}

.table-cell_action-with-icon-text .table-cell-action-with-icon-text_button {
    display: flex;
    align-items: center;
    height: inherit;
    padding: 0;
    border: none;
    background: transparent;
    color: var(--secondary-default);
    cursor: pointer;
    min-width: inherit;
}

.table-cell-text-before-icon {
    margin-right: 5px;
}

.strategy-with-icon .Icon-warning svg {
    fill: var(--negative-default);
}

.strategy-with-icon .table-cell_action-with-icon-text {
    color: var(--primary-default);
}
