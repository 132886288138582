.highcharts-container {
    z-index: 1;
}

@media (max-width: 576px) {
    .PieChart .highcharts-pie {
        width: 100%;
        justify-content: center;
    }
}

@media (max-width: 1023px) {
    .PieChart {
        justify-content: left;
        align-items: flex-start;
    }
}
