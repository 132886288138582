.knowledge-and-experience {
    min-height: 120px;
    display: grid;
}

.knowledge-and-experience > .stepper-content {
    max-width: 100%;
    margin: 0;
}

.knowledge-and-experience .question-group:not(:last-child) {
    margin-bottom: calc(var(--indent-base) * 2);
    border-bottom: var(--border-base);
}

.on-boarding .knowledge-and-experience > .stepper-content {
    max-width: 100%;
    margin: 0;
}

.knowledge-and-experience h2.Title {
    padding-bottom: var(--padding-base);
    padding-left: var(--padding-base);
    padding-right: var(--padding-base);
    margin-bottom: 0;
    margin-left: calc(-1 * var(--padding-base));
    margin-right: calc(-1 * var(--padding-base));
    border-bottom: var(--border-width-base) var(--border-style-base) var(--border-color);
}

.knowledge-and-experience .question-group .Form-control-label {
    margin-bottom: 0;
    font-size: 1.8rem;
    line-height: 1.33333333;
    font-family: var(--body-font-family);
    font-weight: normal;
}

.knowledge-and-experience .question-group .checkbox-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.knowledge-and-experience .question-group .checkbox-wrapper {
    align-items: center;
    margin-top: 20px;
}

.knowledge-and-experience .question-group .checkbox-label {
    font-size: 1.6rem;
    line-height: 1.1875;
}

.knowledge-and-experience .questionnaire-group-content {
    margin: var(--padding-base) auto;
    max-width: 700px;
    width: 100%;
}
