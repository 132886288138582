.expand-universe .stepper-content {
    padding-bottom: var(--padding-base);
}

.expand-universe .Paragraph_secondary ol li {
    margin-bottom: var(--margin-base);
}

.expand-universe .instrument-block {
    border-top: var(--border-base);
    padding-top: var(--margin-base);
    padding-bottom: var(--margin-base);
}

.expand-universe .instrument-block:last-child {
    padding-bottom: 0;
}

.expand-universe .instrument-block .Title {
    --title-3-margin-bottom: 7px;
}

.expand-universe .instrument-block .Form-control-label {
    margin-top: var(--margin-base);
}

.expand-universe .instrument-block .toggle-block {
    display: flex;
    align-items: center;
}

.expand-universe .instrument-block .toggle-block .Toggle {
    margin-right: calc(var(--margin-base) / 2);
}

.expand-universe .instrument-block .toggle-block span {
    color: var(--secondary-default);
    line-height: 1;
}
