.preview-content {
    display: flex;
    padding: var(--padding-base) var(--padding-base) 0;
    margin-bottom: calc(var(--padding-base) * -1);
    height: 100%;
    flex-direction: column;
}

.preview-content-attachments {
    display: block;
    font-size: 14px;
    margin-bottom: 0;
    line-height: 1;
}

.preview-content-attachments .preview-attachments {
    display: inline-block;
    text-decoration: underline;
    color: var(--secondary-default);
}

.preview-content-attachments a {
    color: var(--secondary-default);
    line-height: 1;
}

.preview-content .preview-metadata-from {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.preview-content .preview-date {
    width: 150px;
}

.preview-content .preview-content-metadata,
.preview-content .preview-content-attachments {
    padding: var(--indent-base) 0;
    margin: 0;
    border-top: 1px solid var(--border-color);
}

.preview-content table td:first-child {
    max-width: 80px;
}

.preview-content table td:last-child {
    max-width: 86px;
}

.preview-content table td:nth-child(2) {
    max-width: 140px;
}

.preview-content .Paragraph_primary {
    overflow-y: auto;
    padding: var(--padding-base) 0;
    margin-bottom: var(--padding-base);
    white-space: pre-wrap;
}

.preview-content .button-group {
    display: flex;
    margin: 0 calc(var(--padding-base) * -1);
    padding: var(--padding-base);
    justify-content: space-between;
    border-top: 1px solid var(--border-color);
}
