.review-payment {
    margin-bottom: calc(var(--margin-base) * 2);
}

.review-payment .review-payment-content {
    margin: 0 auto;
    max-width: 698px;
    width: 100%;
}

.review-payment .payment-details-title {
    margin-bottom: calc(var(--margin-base) / -2);
}

.review-payment .review-payment-details .group-title {
    margin-top: calc(var(--margin-base));
}

.review-payment .review-payment-details .group-title .Title {
    margin-bottom: calc(var(--margin-base) / 2);
    font-weight: bold;
}

.review-payment .review-payment-details .Title {
    font-size: 1.4rem;
}

.review-payment .review-payment-info {
    color: var(--primary-default);
    font-size: 1.4rem;
    line-height: 1.57142857;
    margin-bottom: 0;
}

.review-payment .review-payment-description {
    margin-top: calc(var(--margin-base) + 5px);
    padding-bottom: calc(var(--margin-base) / 2);
    margin-bottom: var(--margin-base);
    border-bottom: var(--border-base);
}

.review-payment .buttons-block-row {
    border-top: var(--border-base);
    margin: 0 calc(-1 * var(--padding-base));
    padding: var(--padding-base);
}

.review-payment .payments-header {
    display: flex;
    justify-content: space-between;
    border-bottom: var(--border-base);
    padding: 0 var(--padding-base);
    margin: 0 calc(var(--padding-base) * -1);
}

.review-info {
    margin-top: var(--padding-base);
    padding-bottom: var(--padding-base);
}

.review-payment .Content-box {
    border-radius: 0;
    box-shadow: none;
}

.review-payment .payments-header .Title {
    margin-bottom: var(--title-2-margin-bottom);
}

@media (max-width: 576px) {
    .review-payment .buttons-block-row {
        margin: 0 calc(var(--padding-base) * -1);
    }

    .review-payment .payments-header {
        margin-top: 10px;
    }

    .review-info > .Title {
        margin-top: var(--margin-base);
    }

}
