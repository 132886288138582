.product-selection {
    --radio-big-size: 24px;
    --radio-label-font: 16px;
    --radio-inner-size-big: 12px;
}

.product-selection .radio-buttons {
    margin-top: calc(var(--margin-base) + 5px);
    margin-bottom: var(--padding-base);
}

.product-selection .radio-button-container {
    line-height: 22px;
}

.product-details {
    margin-bottom: calc(var(--margin-base) / 2);
}

.product-details .product-kpi {
    margin: 0 calc(var(--margin-base) / -2);
}

.product-details .product-details-title {
    margin-bottom: calc(var(--margin-base) / 2);
}

.product-details .product-details-title .Title {
    padding-top: var(--padding-base);
    border-top: var(--border-base);
}

.product-selection hr {
    height: 1px;
    margin: 0;
    border: none;
    color: var(--border-color);
    background-color: var(--border-color);
}

.product-details .field {
    display: flex;
    margin: 0 calc(var(--margin-base) / 2) calc(var(--margin-base) / 2) calc(var(--margin-base) / 2);
    padding-bottom: var(--indent-base);
    padding-top: calc(var(--margin-base) / 2);
    justify-content: space-between;
    border-bottom: var(--border-base);
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    color: var(--primary-default);
}

.product-details .field:last-child {
    border-bottom: none;
}

.product-details .field:first-child {
    padding-top: 0;
}

.product-details .field .field-name {
    font-weight: bold;
    width: 50%;
}

.product-details .field .field-value {
    width: 50%;
    text-align: end;
}

.product-key-advantages .Title {
    border-top: var(--border-base);
    padding-top: var(--padding-base);
}

.key-advantage {
    --key-advantage-icon-size: 32px;

    display: flex;
    justify-content: flex-start;
    margin-top: calc(var(--indent-base) / 3);
}

.key-advantage i {
    color: var(--positive-default);
    font-size: var(--key-advantage-icon-size) !important;
    margin-right: var(--margin-base);
}

.key-advantage p {
    margin-bottom: 0;
}

.key-advantage.short-text {
    align-items: center;
    height: 32px;
}

@media (min-width: 768px) {
    .product-selection .radio-buttons .radio-button-container:last-child {
        margin-right: 0;
    }

    .product-selection .radio-buttons .radio-button-container:first-child {
        margin-left: 0;
    }
}

@media (max-width: 767px) {
    .key-advantage {
        align-items: center;
    }
}
