.news-item.Content-box {
    padding: var(--news-padding);
}

.news-item {
    --scale: 1.009;
    overflow: hidden;
    margin: 0;
    border-radius: 8px;
    break-inside: avoid-column;
    box-shadow: none;
    page-break-inside: avoid;
    transform: translateZ(0) scale(1);
    transition: all 0.3s ease-in-out;
    background-color: transparent;
}

@media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
        .news-item {
            display: inline-block;
        }
    }
}

.news-item:hover {
    transform: translate3d(0, 0, 0) scale(var(--scale));
    border-radius: 8px;
}

.news-item:hover .news-item-content {
    color: var(--primary-dark);
}

.news-item .news-item-image {
    width: 100%;
    height: 200px;
    transition: all 0.12s;
    object-fit: cover;
}

.news-item:hover .news-item-image {
    display: block;
    object-fit: cover;
}

.news-item .news-item-image-wrapper {
    background-color: var(--secondary-light);
}

.news-item .news-item-image-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 200px;
    mask: url('../../assets/images/news.svg') no-repeat 50% 50%;
    mask-size: 100px;
    background-color: var(--bright);
    background-size: contain;
    color: var(--bright);
    font-size: 0;
}

.news-item .news-item-content {
    padding: var(--padding-base);
    color: var(--primary-dark);
}

.news-item:first-child {
    margin-top: 0;
}

.news-item .LinkNews {
    overflow: hidden;
    display: inline-block;
    width: 100%;
    box-shadow: var(--content-box-shadow);
    text-decoration: none;
    color: unset;
    background: var(--background-content);
    border-radius: 8px;
}

.news-item .news-item-title {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 11px;
}

.news-item .news-item-title h4 {
    font-weight: 800;
    width: 100%;
}

@media screen and (max-width: 992px) {
    .news-list {
        column-count: 2;
    }

    .news-item .news-item-image {
        height: 250px;
    }
}

@media screen and (max-width: 768px) {
    .news-list {
        column-count: 1;
    }

    .news-item .news-item-image {
        height: 300px;
    }
}
