/* stylelint-disable */
.Auth .detail_content .pf-form_horizontal {
    background-color: var(--background-content);
    box-shadow: var(--content-box-shadow);
    border-radius: 5px;
}

.Auth .detail_content .pf-form_horizontal .content-pane .pf-form_row {
    max-width: 28em;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.Auth .detail_content .validation-summary-errors {
    margin-bottom: var(--margin-base);
}

.Auth .detail_content .validation-summary-errors .enrollDevice {
    height: auto;
    border: none;
    color: var(--secondary-default);
    text-decoration: underline;
    cursor: pointer;
}

.Auth .detail_content .validation-summary-errors .enrollDevice:hover {
    color: var(--navigation-link-color-hover);
    text-decoration: none;
}

.Auth .detail_content .validation-summary-errors .enrollDevice:active {
    color: var(--secondary-darker);
}

.Auth .detail_content .pf-form_horizontal .validation-summary-errors .btn {
    display: block;
    margin: 1em auto 0;
    color: #333;
}

.Auth .detail_content .qr-code {
    display: block;
    width: 14em;
    height: 14em;
    background-size: 14em;
    margin: 1em auto;
}
