.content-pane .or-line {
    position: relative;
    display: block;
    margin: var(--margin-base) auto;
    text-transform: uppercase;
    color: var(--link-color);
}

.content-pane .or-line:before,
.content-pane .or-line:after {
    position: absolute;
    top: 50%;
    display: inline-block;
    height: 1px;
    width: 50%;
    background: var(--border-color);
    margin-top: -0.5px;
    content: '';
}

.content-pane .or-line:before {
    left: 0;
}

.content-pane .or-line:after {
    right: 0;
}

.content-pane .or-line span {
    position: relative;
    z-index: 2;
    display: inline-block;
    background-color: var(--background-content);
    padding: 0 5px;
}

.grecaptcha-badge {
    visibility: hidden;
}
