.cell-actions {
    position: relative;
}

.cell-actions .table-cell_icon {
    color: var(--secondary-default);
}

.cell-actions .table-cell_icon:hover {
    cursor: pointer;
}

.cell-actions .table-cell_disabled {
    pointer-events: none;
}

.cell-actions-panel {
    position: absolute;
    top: 100%;
    right: calc(-1 * var(--indent-base));
    z-index: 20;
    display: none;
    border: 1px solid var(--secondary-light);
    border-radius: 5px;
    background-color: var(--white);
    margin-top: 10px;
}

.cell-actions-panel_visible {
    display: block;
}

.cell-actions-panel:after,
.cell-actions-panel:before {
    position: absolute;
    bottom: 100%;
    right: var(--indent-base);
    display: block;
    width: 0;
    height: 0;
    content: '';
    border-style: solid;
    border-width: 0 9px 11px;
    border-color: transparent;
    border-bottom-color: var(--secondary-light);
}

.cell-actions-panel:before {
    margin-bottom: 1px;
}

.cell-actions-panel:after {
    border-bottom-color: var(--white);
}

.cell-actions-item {
    position: relative;
    padding: 12px var(--margin-base);
    white-space: nowrap;
    min-width: 170px;
    transition: all 0.3s ease 0s;
}

.cell-actions-item:first-child {
    border-radius: 5px 5px 0 0;
}

.cell-actions-item:last-child {
    border-radius: 0 0 5px 5px;
}

.cell-actions-item:not(:first-child):before {
    position: absolute;
    top: 0;
    left: var(--margin-base);
    right: var(--margin-base);
    display: block;
    height: 0;
    content: '';
    border-bottom: var(--border-base);
}

.cell-actions-item:hover {
    background: var(--accent-default);
    color: var(--white);
    cursor: pointer;
}

.cell-actions-item:hover:before,
.cell-actions-item:hover + li:before {
    content: none;
}

.cell-with-more-actions__icon {
    cursor: pointer;
}
