@media (max-width: 768px) {
    .hide-on-mobile {
        display: none !important;
    }
}

.overview-panel {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    min-height: 72px;
}

.overview-panel:not(:last-child) {
    padding-bottom: var(--padding-base);
}

.overview-panel .row {
    margin-bottom: 0 !important;
}

.overview-panel .row + .row {
    margin-top: var(--padding-base);
}

.overview-item {
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    flex-direction: column;
    color: var(--primary-default);
}

.overview-item__title {
    font-size: 20px;
    line-height: 1.1875;
    font-weight: bold;
}

.overview-item__value {
    font-size: 3.2rem;
    line-height: 1.125;
}

.overview-item__value_small {
    color: var(--primary-default);
    font-size: 1.6rem;
    line-height: 2.25;
}

.overview-item__currency {
    color: var(--primary-default);
    margin-left: 5px;
    font-size: 1.6rem;
    line-height: 1.1875;
}

.overview-item__value_positive {
    color: var(--positive-default);
}

.overview-item__value_negative {
    color: var(--negative-default);
}
