.cell-with-new-icon {
    position: relative;
}

.cell-with-new-icon .cell-with-new-icon_icon {
    position: absolute;
    left: -30px;
    top: 50%;
    display: flex;
    align-items: center;
    width: 26px;
    height: 14px;
    font-size: 9px;
    font-weight: bold;
    transform: translateY(-50%);
    background-color: var(--accent-default);
    border-radius: 4px;
    color: var(--white);
    justify-content: center;
}
