.on-boarding_inner {
    --max-content-width: 880px;
    --on-boarding-box-padding: var(--padding-base);
}

@media (max-width: 767px) {
    .on-boarding_inner {
        --on-boarding-box-padding: var(--content-box-mobile-padding);
    }
}

.on-boarding_inner .stepper-content {
    margin: var(--padding-base) auto 0;
    max-width: var(--max-content-width);
    width: 100%;
}

.on-boarding .stepper-content .Content-box {
    margin: 0;
    box-shadow: none;
}

.on-boarding .goal-review .stepper-content {
    margin: 0 calc(-1 * var(--on-boarding-box-padding));
    width: auto;
    max-width: initial;
}

.on-boarding_inner .buttons-block-row {
    margin-left: calc(var(--on-boarding-box-padding) * -1);
    margin-right: calc(var(--on-boarding-box-padding) * -1);
    padding-left: var(--on-boarding-box-padding);
    padding-right: var(--on-boarding-box-padding);
    border-top: var(--border-base);
}

.on-boarding_inner .on-boarding_header {
    display: flex;
    justify-content: space-between;
    border-bottom: var(--border-base);
    padding-bottom: var(--padding-base);
    padding-left: var(--on-boarding-box-padding);
    padding-right: var(--on-boarding-box-padding);
    margin-left: calc(var(--on-boarding-box-padding) * -1);
    margin-right: calc(var(--on-boarding-box-padding) * -1);
}

.on-boarding_inner .on-boarding_header .Title {
    margin-bottom: 0;
}

.on-boarding .PageVariant.wrapper .main {
    --page-variant-main-mw: 415px;
}

@media (max-width: 576px) {
    .on-boarding_inner .on-boarding_header {
        flex-direction: column;
    }

    .on-boarding_inner .on-boarding_header .button {
        margin-top: var(--margin-base);
    }
}
